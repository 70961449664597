export const typeDefs = `
type AggregatedAll {
  """광고"""
  advertisement: SectionAdvertisement!

  """동네 홍보"""
  business: SectionBusiness!

  """중고차 직거래"""
  car: SectionCar!

  """카탈로그 상품광고"""
  catalogProductAdvertisement: SectionCatalogProductAdvertisement!

  """컬렉션 순서"""
  collectionRanks: [CollectionRanks]!

  """업체 상품"""
  commerce: SectionCommerce!

  """동네 생활"""
  community: SectionCommunity!

  """커스텀 에러"""
  error: SearchNetworkError

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """중고 거래"""
  fleaMarket: SectionFleaMarket!

  """알바 영역"""
  job: SectionJob!

  """당근 모임"""
  karrotGroup: SectionKarrotGroup!

  """비즈 프로필 + poi"""
  place: SectionPlace!

  """부동산 직거래"""
  realty: SectionRealty!

  """숏컷"""
  shortcut: SectionShortcut!
}

input AggregatedAllFleaMarketInput {
  withoutCompleted: Boolean!
}

input AggregatedAllInput {
  """중고거래 옵션"""
  fleaMarket: AggregatedAllFleaMarketInput

  """위,경도"""
  location: PlaceLocationInput

  """쿼리"""
  query: String!

  """지역 ID"""
  regionId: String!

  """위,경도 사용 여부"""
  useLocation: Boolean!
}

type AggregatedPreProcess {
  """실험 관련 정보"""
  experiment: SearchExperiment!

  """오타교정 정보"""
  queryPreProcessInfo: AggregatedQueryPreProcessInfo!

  """탭 정보"""
  recommendTabInfo: AggregatedRecommendTabInfo!
}

input AggregatedPreProcessInput {
  """쿼리"""
  query: String!

  """지역 ID"""
  regionId: Int!
}

type AggregatedQueryPreProcessInfo {
  """교정전 쿼리"""
  query: String!

  """교정후 쿼리"""
  reformulationQuery: String!

  """교정 유형"""
  type: ReformulationQueryType!
}

enum AggregatedRecommendTab {
  """통합"""
  ALL

  """동네업체"""
  BIZ_ACCOUNT

  """동네홍보"""
  BUSINESS

  """동네생활"""
  COMMUNITY

  """중고거래"""
  FLEA_MARKET

  """없음"""
  UNKNOWN

  """사람"""
  USERS
}

type AggregatedRecommendTabInfo {
  """탭 추천"""
  recommendTab: AggregatedRecommendTab!

  """탭 순서"""
  tabOrders: [AggregatedTabs!]!
}

enum AggregatedTabs {
  """통합"""
  ALL

  """동네업체"""
  BIZ_ACCOUNT

  """동네홍보"""
  BUSINESS

  """동네생활"""
  COMMUNITY

  """중고거래"""
  FLEA_MARKET

  """사람"""
  USERS
}

type BannerShortcut {
  customText: String!
  customTextDisplay: String!
  decoration: ShortcutDecorationType
}

type BizPostHighlight {
  """하이라이팅 포함된 내용"""
  content: String

  """하이라이팅 포함된 제목"""
  title: String
}

type CarData {
  carHistoryChangesList: [CarHistoryChangeType!]!
  carName: String!
  driveDistance: Int!
  modelYear: Int!
}

enum CarHistoryChangeType {
  CAR_NO
  OWNER
  REGISTER
  UNKNOWN
}

enum CarOptionNameType {
  NOT_SPECIFIED
  SMOKING_FREE
}

enum CarStatusType {
  NONE
  RESERVE
  SALES_STATUS
  TRADED
}

type CarouselCatalogProductAd {
  """카탈로그 상품 광고 리스트"""
  catalogProductAdList: [CatalogProductAd!]!
}

type CatalogProductAd {
  """로깅 용 광고 id"""
  _id: String!

  """DSP/광고주 아이콘 Url"""
  adSourceIconUrl: String!

  """DSP/광고주 명"""
  adSourceName: String!

  """브랜드 명"""
  brandName: String

  """클릭 로깅 url 리스트"""
  clickTrackingUrlsList: [String!]

  """할인율"""
  discountRate: Float

  """광고 숨기기 사유 Url"""
  hideReasonUrl: String!

  """광고 숨기기 Url"""
  hideUrl: String!

  """유니크한 key값: CatalogProductAd#Id"""
  id: ID!

  """impression 로깅 url 리스트"""
  impressionTrackingUrlsList: [String!]

  """아직 사용안함"""
  index: Int!

  """'최저' 표기를 위한 플래그"""
  isLowestPrice: Boolean!

  """몰 명"""
  mallName: String

  """할인 전 판매가"""
  originalPrice: Float!

  """상품 명"""
  productName: String!

  """신고하기 Url"""
  reportUrl: String!

  """할인 판매가"""
  salePrice: Float

  """클릭시 이동할 Url"""
  targetUrl: String!

  """썸네일 이미지 경로"""
  thumbnailImageUrl: String!
}

type CatalogProductAdComponent {
  carouselAdvertisement: CarouselCatalogProductAd
  catalogAdvertisement: CatalogProductAd
}

enum CatalogProductAdComponentType {
  """카탈로그 상품 광고: 캐러셀 뷰"""
  CAROUSEL_ADVERTISEMENT

  """카탈로그 상품 광고: 일반 카드 뷰"""
  CATALOG_ADVERTISEMENT

  """UNKNOWN"""
  UNKNOWN
}

type CatalogProductAdMeta {
  """cursor 값"""
  cursor: String!

  """unique 함을 보장하는 id"""
  id: String!
}

type CatalogProductAdNode {
  component: CatalogProductAdComponent
  componentType: CatalogProductAdComponentType
  meta: CatalogProductAdMeta!
}

type Category {
  _id: Int!
  id: ID!

  """이름"""
  name: String!

  """카테고리 썸네일 (중고거래 검색 결과 등에 사용)"""
  thumbnail: String
}

enum CollectionRanks {
  """광고"""
  AD

  """동네홍보"""
  BUSINESS

  """중고차 직거래"""
  CAR

  """카탈로그 상품광고"""
  CATALOG_PRODUCT_AD

  """업체상품"""
  COMMERCE

  """동네생활"""
  COMMUNITY

  """중고거래"""
  FLEA_MARKET

  """알바"""
  JOB

  """모임"""
  KARROT_GROUP

  """동네업체"""
  PLACE

  """부동산"""
  REALTY

  """정답형 검색"""
  SHORTCUT

  """UNKNOWN"""
  UNKNOWN
}

type CommerceDelivery {
  flag: [CommerceDeliveryFlag!]!
}

type CommerceDeliveryFlag {
  label: String!
  value: String!
}

type CommerceDiscountInfo {
  """할인 시켜줄 금액"""
  amount: Int!

  """통화 단위"""
  currency: String!

  """할인율"""
  ratio: Int!
}

type CommerceProduct {
  """할인 정보"""
  discountInfo: CommerceDiscountInfo

  """최종 판매가(화면 노출가)"""
  displayPrice: CommerceProductPrice!

  """품절 여부"""
  isSoldOut: Boolean!

  """상품 명"""
  name: String!

  """최초 판매가"""
  price: CommerceProductPrice!

  """동 정보"""
  regionName: String!

  """썸네일 이미지"""
  thumbnail: String!
}

type CommerceProductPrice {
  """판매가"""
  amount: Int!

  """통화 단위"""
  currency: String!
}

type CommerceStore {
  name: String!
}

type CommerceUserReaction {
  """리뷰 개수"""
  reviewCount: Int!

  """관심 개수"""
  watchCount: Int!
}

type CommunityComponent {
  community: SearchCommunity
}

enum CommunityComponentType {
  """COMMUNITY"""
  COMMUNITY

  """UNKNOWN"""
  UNKNOWN
}

input CreateUserKeywordNotificationInput {
  """등록할 키워드 알림"""
  keyword: String!
}

union CreateUserKeywordNotificationOutput = CreateUserKeywordNotificationOutputError | CreateUserKeywordNotificationOutputResult

type CreateUserKeywordNotificationOutputData {
  """등록된 키워드 알림의 고유 아이디"""
  id: String!
}

type CreateUserKeywordNotificationOutputError {
  message: String!

  """에러 타입"""
  type: KeywordNotificationErrorType!
}

type CreateUserKeywordNotificationOutputResult {
  userKeyword: CreateUserKeywordNotificationOutputData
}

scalar Date

scalar DateTime

type FeedAutoComplete {
  """카테고리 키워드 목록"""
  categoryKeywords: [FeedAutoCompleteKeyword]!

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """키워드 목록"""
  keywords: [FeedAutoCompleteKeyword]!

  """바로가기 키워드 목록"""
  shortcutKeywords: [FeedAutoCompleteKeyword]!
}

input FeedAutoCompleteDataInput {
  """사용자 입력 값"""
  prefix: String!

  """지역 ID"""
  regionId: Int!
}

type FeedAutoCompleteKeyword {
  """구분을 위한 ID"""
  id: ID!

  """노출되는 이름"""
  name: String!

  """앱 스킴: 일반 추천키워드의 경우, 앱 스킴이 없음"""
  targetUri: String!
}

type FleaMarketComponent {
  carouselAdvertisement: CarouselCatalogProductAd
  catalogAdvertisement: CatalogProductAd
  fleaMarket: SearchFleaMarket
  neighborAd: NeighborAd
  relatedKeyword: RelatedKeywords
}

enum FleaMarketComponentType {
  """카탈로그 상품 광고: 캐러셀 뷰"""
  CAROUSEL_ADVERTISEMENT

  """카탈로그 상품 광고: 일반 카드 뷰"""
  CATALOG_ADVERTISEMENT

  """중고거래"""
  FLEA_MARKET

  """이웃광고"""
  NEIGHBOR_AD

  """연관검색어"""
  RELATED_KEYWORD

  """UNKNOWN"""
  UNKNOWN
}

type FleaMarketFilterRecommend {
  """카테고리 추천"""
  categories: [String!]

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """가격 추천"""
  priceRanges: [String!]
}

input FleaMarketFilterRecommendInput {
  """쿼리"""
  query: String!

  """지역 ID"""
  regionId: Int!
}

type HotKeyword {
  """노출되는 라벨"""
  label: String!

  """스코어"""
  score: Float!

  """
  중고거래: "fleamarket"
  내근처: "local"
  수동선별 키워드: "qualified"
  기존 로직 결과: "initial-model"
  """
  source: String!
}

type HotKeywordQueryInfo {
  testGroup: String!
}

type HotKeywords {
  """실험 관련 정보"""
  experiment: SearchExperiment!

  """바로가기 키워드 목록"""
  keywords: [HotKeyword!]

  """메타 정보"""
  queryInfo: HotKeywordQueryInfo
}

input HotKeywordsDataInput {
  """지역 ID"""
  regionId: Int!
}

"""이미지"""
type Image {
  """미디움 이미지 경로"""
  medium: String

  """썸네일 경로"""
  thumbnail: String

  """오리지널 경로"""
  url: String
}

type JobPost {
  """Id"""
  _id: String!

  """뱃지 목록"""
  authorBadges: [String!]!

  """사업주 회사 명"""
  companyName: String

  """내용"""
  content: String

  """리스트 커서"""
  cursor: String!

  """일 끝나는 시간"""
  endWorkTime: String

  """JobPost#Id"""
  id: ID!

  """이미지 목록"""
  image: JobPostImage

  """근무 요일 협상 여부"""
  isWorkDaysNegotiable: Boolean

  """근무 시간 협상 여부"""
  isWorkTimeNegotiable: Boolean

  """리뷰 수"""
  jobReviewCount: Int

  """공고 끌올 날짜"""
  lastBringUpDate: DateTime

  """공고 게시 날짜"""
  publishedAt: DateTime

  """일할 수 있는 지역"""
  regionName: String!

  """임금"""
  salary: Int

  """월급 / 일급 / 시급 / 건당"""
  salaryType: SalaryType!

  """일 시작 시간"""
  startWorkTime: String

  """이동할 화면"""
  targetUri: String

  """타이틀"""
  title: String

  """일하는 날짜"""
  workDates: [DateTime!]!

  """일하는 요일"""
  workDays: [WorkDayType!]!

  """게시글의 좌표 값"""
  workplaceLocationCoordinates: WorkplaceLocationCoordinate
}

type JobPostImage {
  """이미지 썸네일"""
  thumbnail: String!
}

type KarrotGroup {
  _id: String!

  """카테고리 아이디"""
  categoryId: String!

  """카테고리 이름"""
  categoryName: String!

  """모임 설명"""
  description: String!
  id: ID!

  """방금 활동"""
  isJustActivated: Boolean!

  """멤버 수"""
  memberCount: Int!

  """이름"""
  name: String!

  """대표 이미지"""
  presentationImage: Image

  """지역 아이디"""
  regionId: String!

  """지역 이름"""
  regionName: String!

  """활동 멤버 정보"""
  someOfMemberProfiles: [KarrotGroupMemberProfile!]
}

type KarrotGroupMemberProfile {
  id: ID!

  """프로필 이미지"""
  profileImageUrl: String
}

enum KeywordNotificationErrorType {
  ERROR_TYPE_FLEAMARKET_KEYWORD_ALREADY_EXISTS
  ERROR_TYPE_FLEAMARKET_KEYWORD_EXCEED_LIMIT
  ERROR_TYPE_FLEAMARKET_KEYWORD_NOT_ALLOWED
  ERROR_TYPE_UNSPECIFIED
}

input MapCoordinatesInput {
  """지도의 중앙점"""
  center: PlaceLocationInput!

  """지도의 북서쪽 좌표"""
  northWest: PlaceLocationInput!

  """지도의 남동쪽 좌표"""
  southEast: PlaceLocationInput!
}

type Mutation {
  """키워드 알림을 등록합니다."""
  createUserKeywordNotification(input: CreateUserKeywordNotificationInput!): CreateUserKeywordNotificationOutput
  deleteAllRecentSearchQuery: RecentSearchMutationOutput
  deleteRecentSearchQuery(input: RecentSearchDeleteQueryInput!): RecentSearchMutationOutput
  deleteRecentSearchShortcut(input: RecentSearchDeleteShortcutInput!): RecentSearchMutationOutput
}

type NeighborAd {
  """Id"""
  _id: String!

  """채팅 갯수"""
  chatRoomsCount: Int!

  """생성일시"""
  createdAt: DateTime

  """이미지"""
  firstImage: Image

  """이미지 Id"""
  firstImageId: String

  """광고 숨기기 Url"""
  hideUrl: String!

  """NeighborAd#Id"""
  id: ID!

  """이웃광고 가이드 버튼 정보"""
  info: NeighborAdInfo!

  """이웃광고 로깅 정보"""
  logging: NeighborAdLogging!

  """가격"""
  price: Float

  """끌올일시"""
  publishedAt: DateTime

  """지역 Id"""
  regionId: String

  """지역 이름"""
  regionName: String!

  """신고하기 Url"""
  reportUrl: String!

  """slot"""
  slot: Int

  """상태"""
  status: NeighborAdStatus

  """타겟 앱스킴"""
  targetUri: String!

  """제목"""
  title: String!

  """관심 갯수"""
  watchesCount: Int!
}

type NeighborAdInfo {
  """이웃광고 가이드 버튼 정보"""
  button: NeighborAdInfoButton!

  """이웃광고 가이드 버튼 로깅 정보"""
  logging: NeighborAdLogging
}

type NeighborAdInfoButton {
  """버튼에 대한 로깅 정보"""
  logging: NeighborAdLogging!

  """클릭시 이동할 Target URL"""
  targetUri: String!

  """버튼 라벨"""
  text: String!
}

type NeighborAdLogging {
  """click url"""
  click: String!

  """impression url"""
  impression: String!
}

enum NeighborAdStatus {
  """거래완료"""
  CLOSED

  """판매중"""
  ONGOING

  """예약됨"""
  RESERVED

  """알 수 없음"""
  UNKNOWN
}

"""
PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
"""
type PageInfo {
  """
  The cursor corresponding to the last nodes in edges. Null if the connection is empty.
  """
  endCursor: String

  """
  Used to indicate whether more edges exist following the set defined by the clients arguments.
  """
  hasNextPage: Boolean!

  """
  Used to indicate whether more edges exist prior to the set defined by the clients arguments.
  """
  hasPreviousPage: Boolean!

  """
  The cursor corresponding to the first nodes in edges. Null if the connection is empty.
  """
  startCursor: String
}

type PlaceBestReview {
  content: String
  userName: String
}

enum PlaceCollectionName {
  """BIZ_ACCOUNT"""
  BIZ_ACCOUNT

  """LOCAL_PROFILE"""
  LOCAL_PROFILE

  """POI"""
  POI

  """UNKNOWN"""
  UNKNOWN
}

type PlaceComponent {
  banner: SearchPlaceCreateBanner
  place: SearchPlace
}

enum PlaceComponentType {
  """동네업체"""
  PLACE

  """장소등록 배너"""
  PLACE_CREATE_BANNER

  """UNKNOWN"""
  UNKNOWN
}

type PlaceDistance {
  """m, km"""
  unit: PlaceDistanceUnit!

  """거리"""
  value: Int!
}

enum PlaceDistanceUnit {
  KILOMETER
  METER
  UNKNOWN
}

input PlaceLocationInput {
  """위도"""
  latitude: Float!

  """경도"""
  longitude: Float!
}

enum PlaceOperationStatus {
  OPERATION_STATUS_BREAK_TIME
  OPERATION_STATUS_CLOSED_FOR_PREPARATION
  OPERATION_STATUS_CLOSED_TODAY
  OPERATION_STATUS_OPEN
  OPERATION_STATUS_OPEN_24_HOURS
  OPERATION_STATUS_UNSPECIFIED
  UNKNOWN
}

type Query {
  """통합 탭 검색 결과"""
  aggregatedAll(data: AggregatedAllInput!, referrer: SearchReferrerInput!): AggregatedAll!

  """통합검색 탭 추천"""
  aggregatedPreProcess(data: AggregatedPreProcessInput!, referrer: SearchReferrerInput!): AggregatedPreProcess!

  """통합 검색, 키워드 목록"""
  feedAutoComplete(data: FeedAutoCompleteDataInput!, referrer: SearchReferrerFunnelFromInput!): FeedAutoComplete!

  """중고거래 카테고리 목록"""
  fleaMarketCategories: [Category!]!

  """중고거래 필터 추천"""
  fleaMarketFilterRecommend(data: FleaMarketFilterRecommendInput!, referrer: SearchReferrerInput!): FleaMarketFilterRecommend!

  """중고거래 검색, 연관 검색어"""
  fleaMarketRelatedKeywords(data: RelatedKeywordsDataInput!, referrer: SearchReferrerInput!): [String!]

  """통합 검색, 인기 키워드"""
  hotKeywords(data: HotKeywordsDataInput!, referrer: SearchReferrerFunnelFromInput!): HotKeywords
  recentSearchQueries(data: RecentSearchQueryInput!): RecentSearchQueryOutput!

  """내 주변 지역 정보"""
  regionRanges(where: RegionRangesWhereInput!): [RegionRange!]!

  """동네 홍보 검색"""
  searchBizPosts(after: String, data: SearchBizPostsDataInput!, first: Int!, referrer: SearchReferrerInput!): SearchBizPostConnection!

  """중고거래 검색"""
  searchFleaMarkets(after: String, data: SearchFleaMarketsDataInput!, first: Int!, referrer: SearchReferrerInput!): SearchFleaMarketConnection!

  """동네업체 + POI 검색"""
  searchPlaces(after: String, data: SearchPlacesDataInput!, first: Int!, referrer: SearchReferrerInput!): SearchPlaceConnection!

  """유저 검색"""
  searchUsers(after: String, data: SearchUsersDataInput!, first: Int!, referrer: SearchReferrerInput!): SearchUserConnection!

  """동네 생활 검색"""
  searchV4Communities(after: String, data: SearchV4CommunitiesDataInput!, first: Int!, referrer: SearchReferrerInput!): SearchV4CommunityConnection!

  """키워드 정보 (키워드 알림 등록 여부, 금지 키워드 여부)"""
  userKeyword(data: UserKeywordDataInput!): UserKeyword!
}

type Realty {
  _id: String!

  """영역 타이틀"""
  areaTitle: String

  """채팅 수"""
  chatCount: Int!

  """생성 시간"""
  createdAt: DateTime!

  """층수 타이틀"""
  floorTitle: String
  id: ID!

  """집 주인 인증 여부"""
  isWriterVerified: Boolean!

  """지역 타이틀"""
  locationTitle: String

  """방수 타이틀"""
  manageCostTitle: String

  """월세/전세/단기 가능 여부 텍스트"""
  otherSalesTypes: [String!]!

  """이미지"""
  picture: Image

  """가격 타이틀"""
  priceTitle: String!

  """끌올 시간"""
  publishedAt: DateTime

  """끌올 수"""
  pullUpCount: Int!

  """판매 타입 타이틀"""
  salesTypeTitle: String!

  """게시글 상태"""
  status: RealtyStatus!

  """찜하기 수"""
  voteCount: Int!
}

enum RealtyStatus {
  """판매중"""
  ON_SALE

  """예약됨"""
  RESERVED

  """거래완료"""
  TRADE_COMPLETED

  """알 수 없음"""
  UNKNOWN
}

type RecentSearch {
  eventTime: String!
  node: RecentSearchNode!
  type: RecentSearchType!
}

input RecentSearchDeleteQueryInput {
  query: String!
}

input RecentSearchDeleteShortcutInput {
  shortcutId: String!
}

union RecentSearchMutationOutput = RecentSearchMutationOutputError | RecentSearchMutationOutputResult

type RecentSearchMutationOutputError {
  """에러 타입"""
  type: String!
}

type RecentSearchMutationOutputResult {
  result: String
}

union RecentSearchNode = RecentSearchQuery | RecentSearchShortcut

input RecentSearchOptimisticMutationInput {
  id: ID!
}

type RecentSearchOptimisticMutationOutputResult {
  id: ID!
}

type RecentSearchQuery {
  id: ID!
  query: String!
}

type RecentSearchQueryError {
  """에러 타입"""
  type: String!
}

input RecentSearchQueryInput {
  """지역 ID"""
  regionId: Int!
}

type RecentSearchQueryOutput {
  error: RecentSearchQueryError
  experiment: SearchExperiment!
  queries: [RecentSearch!]!
}

type RecentSearchShortcut {
  _id: String!
  clickAction: SearchAction
  content: String!
  id: ID!
  imageUrl: String!
  name: String!
  targetUri: String!
  templateName: String!
  title: String!
}

enum RecentSearchType {
  RECENT_SEARCH_TYPE_QUERY
  RECENT_SEARCH_TYPE_SHORTCUT
  RECENT_SEARCH_TYPE_UNSPECIFIED
}

enum ReformulationQueryType {
  """교정안함"""
  NONE

  """오타교정"""
  TYPO_CORRECTION
}

"""지역"""
type Region {
  """전체 이름"""
  fullname: String

  """이름"""
  name: String

  """1단계 지역 이름"""
  name1: String

  """2단계 지역 이름"""
  name2: String

  """3단계 지역 이름"""
  name3: String
}

type RegionRange {
  """거리"""
  distance: Int!

  """지역 범위 이름"""
  name: RegionRangeName!

  """지역 이름 목록"""
  regions: [String!]!
}

enum RegionRangeName {
  ADJACENT
  MY
  RANGE_2
  RANGE_3
  UNKNOWN
}

input RegionRangesWhereInput {
  """지역 ID"""
  regionId: Int!
}

"""연관검색어 키워드"""
type RelatedKeyword {
  name: String!
  type: RelatedKeywordType!
}

type RelatedKeywordFleaMarketTop {
  relatedKeywords: RelatedKeywords
}

"""연관검색어 키워드리스트 타입"""
enum RelatedKeywordType {
  """키워드"""
  KEYWORD

  """바로가기"""
  SHORTCUT

  """정해지지 않음"""
  UNSPECIFIED
}

type RelatedKeywords {
  keywords: [RelatedKeyword!]
  slot: Int!
  template: RelatedKeywordsTemplateType
}

input RelatedKeywordsDataInput {
  """키워드"""
  keyword: String!

  """지역 ID"""
  regionId: Int!
}

enum RelatedKeywordsTemplateType {
  LIST_VIEW
  UNKNOWN
}

enum SalaryType {
  """일급"""
  DAILY

  """시급"""
  HOURLY

  """월급"""
  MONTHLY

  """건당"""
  PER_CASE

  """알수없음"""
  UNKNOWN
}

type SearchAction {
  method: String!
  payload: SearchActionPayload
  url: String!
}

type SearchActionPayload {
  actionType: SearchActionType!
  clearSearchQueryActionData: SearchClearSearchQueryActionData
  clearShortcutActionData: SearchClearShortcutActionData
  clickShortcutActionData: SearchClickShortcutActionData
  deleteRecentQueryActionData: SearchDeleteRecentQueryActionData
  deleteRecentShortcutActionData: SearchDeleteRecentShortcutActionData
  searchQueryActionData: SearchQueryActionData
}

enum SearchActionType {
  ACTION_TYPE_CLEAR_SEARCH_QUERY
  ACTION_TYPE_CLEAR_SHORTCUT
  ACTION_TYPE_CLICK_SHORTCUT
  ACTION_TYPE_DELETE_RECENT_QUERY
  ACTION_TYPE_DELETE_RECENT_SHORTCUT
  ACTION_TYPE_SEARCH_QUERY
  ACTION_TYPE_UNSPECIFIED
}

type SearchBizAd {
  """Id"""
  _id: String!

  """비즈 프로필 정보"""
  bizProfile: SearchBizAdProfile!

  """"""
  clickUrl: String!

  """광고 숨기기 사유 Url"""
  hideReasonUrl: String!

  """광고 숨기기 Url"""
  hideUrl: String!

  """SearchBizAd#Id"""
  id: ID!

  """"""
  impressionUrl: String!

  """신고하기 Url"""
  reportUrl: String!

  """"""
  slot: Int
}

type SearchBizAdArea {
  topAdsList: [SearchBizAd!]
}

type SearchBizAdProfile {
  """비즈 프로필 카테고리 Url"""
  category: String!

  """심의필 정보"""
  deliberationCode: String!

  """비즈 프로필 설명"""
  description: String!

  """비즈 프로필 즐겨찾기 숫자"""
  followerCount: Int!

  """비즈 프로필 쿠폰 유무"""
  hasCoupon: Boolean!

  """비즈 프로필 이미지"""
  imageUrl: String!

  """비즈 프로필 이름"""
  name: String!

  """비즈 프로필 지역"""
  region: String!

  """예약가능"""
  reservable: Boolean!

  """비즈 프로필 리뷰 숫자"""
  reviewCount: Int!

  """별점"""
  starRating: String

  """바로구매 가능 여부 true: 바로구매 가능"""
  storeOnSale: Boolean!

  """비즈 프로필 타겟 URL"""
  targetUri: String!
}

type SearchBizPost {
  _id: String!

  """비즈프로필의 이미지"""
  bizaccountImageUrl: String

  """비즈프로필의 이름"""
  bizaccountName: String

  """관심 갯수"""
  bookmarkCount: Int!

  """카테고리 ID"""
  categoryId: String!

  """댓글 갯수"""
  commentCount: Int

  """내용"""
  content: String

  """생성일시"""
  createdAt: DateTime

  """이미지"""
  firstImageUrl: String

  """쿠폰 유무"""
  hasCoupon: Boolean!

  """하이라이트 제목, 내용"""
  highlight: BizPostHighlight
  id: ID!

  """단골 여부"""
  isRegular: Boolean

  """가격"""
  price: Int

  """지역 이름"""
  regionName: String!

  """제목"""
  title: String!

  """유저 타입 (BUSINESS_ACCOUNT | USER)"""
  userType: String!
}

type SearchBizPostConnection {
  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
  """
  edges: [SearchBizPostEdge!]!

  """connection에 대한 커스텀 에러"""
  error: SearchNetworkError

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
  """
  pageInfo: PageInfo!
}

type SearchBizPostEdge {
  """https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor"""
  cursor: String!

  """https://facebook.github.io/relay/graphql/connections.htm#sec-Node"""
  node: SearchBizPost!
}

enum SearchBizPostFilterInputSort {
  """최신순"""
  RECENT

  """정확도순"""
  RELEVANT
}

input SearchBizPostsDataInput {
  """검색어"""
  query: String!

  """지역 ID"""
  regionId: Int!

  """정렬"""
  sort: SearchBizPostFilterInputSort
}

type SearchCar {
  _id: String!

  """차량 정보"""
  carData: CarData

  """매물 옵션 리스트"""
  carOptionNamesList: [CarOptionNameType!]!

  """Article 상태"""
  carStatus: CarStatusType!

  """채팅 카운트"""
  chatCount: Int!

  """매물 설명"""
  content: String!

  """매물 등록시간"""
  createdAt: DateTime

  """피드노출시 가격정보"""
  displayPrice: String!

  """이미지"""
  firstImage: Image
  id: ID!

  """가격"""
  price: Int!

  """sorting을 위한 시간(끌올)"""
  publishedAt: DateTime

  """끌올 횟수"""
  pullUpCount: Int!

  """리전정보"""
  regionName: String!

  """타이틀"""
  title: String!

  """매물 수정시간"""
  updatedAt: DateTime

  """유저 아이디"""
  userId: Int!

  """찜하기 카운트"""
  voteCount: Int!
}

type SearchCarFilter {
  companyIdsList: [Int]!
  seriesIdsList: [Int]!
  subseriesIdsList: [Int]!
}

type SearchClearSearchQueryActionData {
  userId: Int!
}

type SearchClearShortcutActionData {
  userId: Int!
}

type SearchClickShortcutActionData {
  shortcutId: String!
  userId: Int!
}

type SearchCommerce {
  _id: String!

  """배송 정보"""
  delivery: CommerceDelivery!
  id: ID!

  """상품 정보"""
  product: CommerceProduct!

  """업체 정보"""
  store: CommerceStore!

  """유저 반응 정보"""
  userReaction: CommerceUserReaction!
}

type SearchCommunity {
  _id: String!

  """카테고리 ID"""
  categoryId: String!

  """카테고리 이름"""
  categoryName: String!

  """댓글 하이라이트"""
  commentHighlight: String

  """댓글 갯수"""
  commentsCount: Int!

  """내용"""
  content: String!

  """<em />으로 하이라이팅된 content"""
  contentHighlight: String!

  """생성일시"""
  createdAt: DateTime

  """감정 갯수"""
  emoticonsCount: Int!

  """첫 이미지"""
  firstImage: Image
  id: ID!

  """이미지 갯수"""
  imagesCount: Int!

  """관심 이름"""
  interestName: String

  """관심 아이디 (questions)"""
  interestUid: String

  """지역 이름"""
  regionName: String!

  """제목"""
  title: String!

  """<em />으로 하이라이팅된 title"""
  titleHighlight: String!

  """조회수"""
  viewCount: Int!
}

type SearchCommunityConnection {
  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
  """
  edges: [SearchCommunityEdge!]!

  """connection에 대한 커스텀 에러"""
  error: SearchNetworkError

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
  """
  pageInfo: PageInfo!
}

type SearchCommunityEdge {
  """https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor"""
  cursor: String!

  """https://facebook.github.io/relay/graphql/connections.htm#sec-Node"""
  node: SearchCommunityNode!
}

type SearchCommunityMeta {
  cursor: String!
  id: String!
}

type SearchCommunityNode {
  component: CommunityComponent
  componentType: CommunityComponentType
  meta: SearchCommunityMeta!
}

type SearchDeleteRecentQueryActionData {
  query: String!
  userId: Int!
}

type SearchDeleteRecentShortcutActionData {
  shortcutId: String!
  userId: Int!
}

type SearchExperiment {
  experimentSegments: [SearchExperimentSegments!]!
  isExperimentTriggered: Boolean!
}

type SearchExperimentSegments {
  key: String!
  name: String!
  type: String!
}

type SearchFleaMarket {
  """로깅, 앱스킴용 article id"""
  _id: String!

  """무료나눔 이벤트의 경우 비딩한 횟수"""
  bidsCount: Int!

  """카테고리"""
  category: Category

  """카테고리 ID"""
  categoryId: String!

  """채팅 갯수"""
  chatRoomsCount: Int!

  """생성일시"""
  createdAt: DateTime

  """이미지"""
  firstImage: Image

  """무료나눔 이벤트 게시글인지 여부"""
  freeShareEventEnabled: Boolean!

  """하이퍼로컬 라운지 명"""
  hyperLocalGroupName: String

  """유니크한 key값: SearchFleaMarket#Id"""
  id: ID!

  """통검탭 전체 문서 기준 index"""
  index: Int!

  """가격"""
  price: Float

  """끌올일시"""
  publishedAt: DateTime

  """지역 이름"""
  regionName: String!

  """끌올 횟수"""
  republishCount: Int!

  """나눔 키워드 변경"""
  sharingText: String

  """상태"""
  status: SearchFleaMarketStatus

  """제목"""
  title: String!

  """관심 갯수"""
  watchesCount: Int!
}

type SearchFleaMarketConnection {
  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
  """
  edges: [SearchFleaMarketEdge!]!

  """connection에 대한 커스텀 에러"""
  error: SearchNetworkError

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
  """
  pageInfo: PageInfo!
}

type SearchFleaMarketEdge {
  """https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor"""
  cursor: String!

  """https://facebook.github.io/relay/graphql/connections.htm#sec-Node"""
  node: SearchFleaMarketNode!
}

enum SearchFleaMarketFilterInputSort {
  """최신순"""
  RECENT

  """정확도순"""
  RELEVANT
}

type SearchFleaMarketMeta {
  """cursor 값"""
  cursor: String!

  """FleaMarket 아이템 node 내 unique 함을 보장하는 id"""
  id: String!
}

type SearchFleaMarketNode {
  component: FleaMarketComponent
  componentType: FleaMarketComponentType
  meta: SearchFleaMarketMeta!
}

enum SearchFleaMarketStatus {
  """거래완료"""
  CLOSED

  """판매중"""
  ONGOING

  """예약됨"""
  RESERVED

  """알 수 없음"""
  UNKNOWN
}

input SearchFleaMarketsDataInput {
  """(필터) 카테고리 ID 목록"""
  categoryIds: [String!]

  """App이 무료나눔 이벤트 기능을 지원하는지 여부: ios 6.3.7, android 6.2.3 이상 버전에서 지원"""
  freeShareEventEnabled: Boolean!

  """(필터) 거래완료 안보기"""
  hideClosedArticles: Boolean!

  """(필터) 가격 끝"""
  priceGte: Float

  """(필터) 가격 시작"""
  priceLte: Float

  """검색어"""
  query: String!

  """지역 ID"""
  regionId: Int!

  """(필터) 검색할 동네 범위"""
  regionRange: SearchFleaMarketsDataInputRegionRange

  """(필터) 정렬"""
  sort: SearchFleaMarketFilterInputSort
}

enum SearchFleaMarketsDataInputRegionRange {
  """1단계"""
  ADJACENT

  """내 동네"""
  MY

  """2단계"""
  RANGE_2

  """3단계"""
  RANGE_3
}

type SearchNetworkError {
  """에러 타입"""
  type: SearchNetworkErrorType!
}

enum SearchNetworkErrorType {
  """어뷰저 탐지"""
  ERROR_TYPE_ABUSER_DETECTED

  """100글자 이상 쿼리"""
  ERROR_TYPE_LONG_TAIL_QUERY

  """null query"""
  ERROR_TYPE_NULL_QUERY

  """authToken 만료"""
  ERROR_TYPE_UNAUTHENTICATED

  """정의되지 않은 에러"""
  ERROR_TYPE_UNSPECIFIED
}

type SearchPlace {
  """로깅, 앱스킴용 article id"""
  _id: String!

  """도로명 주소"""
  address: String!

  """긍정 후기"""
  bestReview: PlaceBestReview

  """배경 이미지"""
  bgImage: Image

  """카테고리 id"""
  categoryId: String!

  """카테고리 이름"""
  categoryText: String!

  """collection name: poi, business-account"""
  collectionName: PlaceCollectionName!

  """생성일시"""
  createdAt: DateTime!

  """비즈프로필 상세 정보"""
  description: String

  """비즈프로필 소개글 이모지 제거 버전"""
  descriptionWithoutEmoji: String!

  """카테고리 이름"""
  displayCategoryName: String!

  """업체 거리 정보"""
  distance: PlaceDistance

  """관심 갯수"""
  followsCount: Int!

  """쿠폰 유무"""
  hasCoupon: Boolean!

  """유니크한 key값: SearchPlace#Id"""
  id: ID!

  """이미지"""
  images: [Image!]!

  """지번 주소"""
  jibunAddress: String!

  """위도,경도 정보"""
  location: String!

  """이름"""
  name: String!

  """영업 정보"""
  operationStatus: PlaceOperationStatus

  """프로필 이미지"""
  picture: Image

  """지역 이름"""
  regionName: String!

  """예약가능"""
  reservable: Boolean!

  """후기 갯수 (smb + poi + tag count)"""
  reviewCount: Int!

  """업체 별점"""
  reviewStarRatingAverage: Float!

  """바로구매 가능 여부 true: 바로구매 가능"""
  storeOnSale: Boolean!

  """업체 카테고리 테마 정보"""
  themeIds: [String!]!
}

type SearchPlaceConnection {
  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
  """
  edges: [SearchPlaceEdge!]!

  """connection에 대한 커스텀 에러"""
  error: SearchNetworkError

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
  """
  pageInfo: PageInfo!
}

type SearchPlaceCreateBanner {
  _id: String!
  content: String!
  id: ID!
  name: String!
}

type SearchPlaceEdge {
  """https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor"""
  cursor: String!

  """https://facebook.github.io/relay/graphql/connections.htm#sec-Node"""
  node: SearchPlaceNode!
}

enum SearchPlaceFilterInputSort {
  """거리순"""
  DISTANCE

  """정확도순"""
  RELEVANT
}

type SearchPlaceMeta {
  """cursor 값"""
  cursor: String!

  """Place 아이템 node 내 unique 함을 보장하는 id"""
  id: String!
}

type SearchPlaceNode {
  component: PlaceComponent!
  componentType: PlaceComponentType!
  meta: SearchPlaceMeta!
}

input SearchPlacesDataInput {
  """위,경도"""
  location: PlaceLocationInput

  """현 위치 검색"""
  mapCoordinates: MapCoordinatesInput

  """검색어"""
  query: String!

  """지역 ID"""
  regionId: Int!

  """(필터) 정렬"""
  sort: SearchPlaceFilterInputSort

  """위,경도 사용 여부"""
  useLocation: Boolean!
}

type SearchQueryActionData {
  query: String!
  userId: Int!
}

enum SearchReferrerDepthName {
  """디스커버 화면"""
  DISCOVERY

  """검색 결과 지도 화면"""
  MAP

  """검색 결과 More 화면"""
  MORE_RESULT

  """검색 결과 화면"""
  RESULT
}

enum SearchReferrerFunnelFromId {
  """동네생활 퍼널"""
  COMMUNITY

  """동네생활 상세 페이지 컨텍스트"""
  COMMUNITY_ARTICLE_CONTEXT

  """큐레이션 퍼널"""
  CURATION_PAGE

  """중고거래 상세 페이지 컨텍스트"""
  FLEAMARKET_ARTICLE_CONTEXT

  """중고거래 카테고리 퍼널"""
  FLEA_MARKET_CATEGORY

  """홈 퍼널"""
  HOME

  """홈 피드 게시글"""
  HOME_FEED

  """내근처 퍼널"""
  NEARBY

  """알수없음"""
  UNKNOWN
}

input SearchReferrerFunnelFromInput {
  """어떤 funnel에서 검색 요청한 것인지"""
  funnelFromId: SearchReferrerFunnelFromId!
}

input SearchReferrerInput {
  """어떤 funnel에서 검색 요청한 것인지"""
  funnelFromId: String!

  """쿼리 FROM"""
  queryFromId: String!

  """쿼리 ID"""
  queryId: String!

  """어떤 단계의 화면에서 요청한 것인지"""
  screenDepthName: SearchReferrerDepthName!

  """Funnel ID, 검색 시작 지점에서 발급"""
  searchFunnelId: String!

  """어떤 Tab에서 요청한 것인지"""
  tab: SearchReferrerTab!
}

enum SearchReferrerTab {
  """통합"""
  ALL

  """동네업체"""
  BIZ_ACCOUNT

  """동네홍보"""
  BUSINESS

  """동네생활"""
  COMMUNITY

  """중고거래"""
  FLEA_MARKET

  """알수없음"""
  UNKNOWN

  """이웃"""
  USERS
}

type SearchShortcut {
  _id: String!
  clickAction: SearchAction
  detail: ShortcutDetail!
  id: ID!
  imageUrl: String
  name: String!
  targetUri: String!
  templateName: String!
}

type SearchUser {
  _id: Int!

  """External ID"""
  externalId: String!
  id: ID!

  """이미지"""
  image: Image

  """닉네임"""
  nickname: String!

  """지역 이름"""
  regionName: String!
}

type SearchUserConnection {
  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
  """
  edges: [SearchUserEdge!]!

  """connection에 대한 커스텀 에러"""
  error: SearchNetworkError

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
  """
  pageInfo: PageInfo!
}

type SearchUserEdge {
  """https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor"""
  cursor: String!

  """https://facebook.github.io/relay/graphql/connections.htm#sec-Node"""
  node: SearchUser!
}

enum SearchUserStatus {
  """거래완료"""
  CLOSED

  """판매중"""
  ONGOING

  """예약됨"""
  RESERVED

  """알 수 없음"""
  UNKNOWN
}

input SearchUsersDataInput {
  """검색어"""
  query: String!

  """지역 ID"""
  regionId: Int!
}

input SearchV4CommunitiesDataInput {
  """검색어"""
  query: String!

  """지역 ID"""
  regionId: Int!

  """정렬"""
  sort: SearchV4CommunityFilterInputSort!
}

type SearchV4Community {
  _id: String!

  """카테고리 ID"""
  categoryId: String!

  """카테고리 이름"""
  categoryName: String!

  """댓글 하이라이트"""
  commentHighlight: String

  """댓글 갯수"""
  commentsCount: Int!

  """내용"""
  content: String!

  """<em />으로 하이라이팅된 content"""
  contentHighlight: String!

  """생성일시"""
  createdAt: DateTime

  """감정 갯수"""
  emoticonsCount: Int!

  """첫 이미지"""
  firstImage: Image
  id: ID!

  """이미지 갯수"""
  imagesCount: Int!

  """관심 이름"""
  interestName: String

  """관심 아이디 (questions)"""
  interestUid: String

  """지역 이름"""
  regionName: String!

  """제목"""
  title: String!

  """<em />으로 하이라이팅된 title"""
  titleHighlight: String!

  """조회수"""
  viewCount: Int!
}

type SearchV4CommunityConnection {
  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types
  """
  edges: [SearchV4CommunityEdge!]!

  """connection에 대한 커스텀 에러"""
  error: SearchNetworkError

  """실험 관련 정보"""
  experiment: SearchExperiment!

  """
  https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo
  """
  pageInfo: PageInfo!
}

type SearchV4CommunityEdge {
  """https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor"""
  cursor: String!

  """https://facebook.github.io/relay/graphql/connections.htm#sec-Node"""
  node: SearchV4CommunityNode!
}

enum SearchV4CommunityFilterInputSort {
  """최신순"""
  RECENT

  """정확도순"""
  RELEVANT
}

type SearchV4CommunityMeta {
  cursor: String!
  id: String!
}

type SearchV4CommunityNode {
  component: V4CommunityComponent
  componentType: V4CommunityComponentType
  meta: SearchV4CommunityMeta!
}

type SectionAdvertisement {
  payload: [SearchBizAd!]!
}

type SectionBusiness {
  hasNextPage: Boolean!
  payload: [SearchBizPost!]!
}

type SectionCar {
  hasNextPage: Boolean!
  isTargetHome: Boolean!
  payload: [SearchCar!]!
  searchFilter: SearchCarFilter
}

type SectionCatalogProductAdvertisement {
  payload: [CatalogProductAdNode!]!
}

type SectionCommerce {
  hasNextPage: Boolean!
  payload: [SearchCommerce!]!
}

type SectionCommunity {
  hasNextPage: Boolean!
  payload: [SearchCommunityNode!]!
}

type SectionFleaMarket {
  hasNextPage: Boolean!
  payload: [SearchFleaMarketNode!]!
}

type SectionJob {
  hasNextPage: Boolean!
  isTargetHome: Boolean!
  payload: [JobPost!]!
}

type SectionKarrotGroup {
  hasNextPage: Boolean!
  isTargetHome: Boolean!
  payload: [KarrotGroup!]!
}

type SectionPlace {
  hasNextPage: Boolean!
  payload: [SearchPlaceNode!]!
}

type SectionRealty {
  hasNextPage: Boolean!
  isTargetHome: Boolean!
  payload: [Realty!]!
}

type SectionShortcut {
  payload: SearchShortcut
}

type ServiceShortcut {
  content: String!
  title: String!
}

type ShortcutDecorationType {
  """클릭했을때 배경 색상"""
  activeBgColor: String

  """배경 색상"""
  bgColor: String

  """텍스트 색상"""
  textColor: String
}

union ShortcutDetail = BannerShortcut | ServiceShortcut

scalar Time

type UserKeyword {
  id: ID!

  """검색 금지어 여부(ex. 성인용품)"""
  isBannedKeyword: Boolean!

  """키워드 알림 금지어 여부(ex. 나눔)"""
  isNotificationBannedKeyword: Boolean!

  """유저의 키워드 알림 등록 여부"""
  isRegistered: Boolean!

  """서버에서 판단한 키워드"""
  keyword: String!
}

input UserKeywordDataInput {
  """쿼리"""
  query: String!
}

type V4CommunityComponent {
  community: SearchV4Community
}

enum V4CommunityComponentType {
  """COMMUNITY"""
  COMMUNITY

  """UNKNOWN"""
  UNKNOWN
}

enum WorkDayType {
  """금요일"""
  FRI

  """월요일"""
  MON

  """토요일"""
  SAT

  """일요일"""
  SUN

  """목요일"""
  THU

  """화요일"""
  TUE

  """수요일"""
  WED
}

type WorkplaceLocationCoordinate {
  lat: Float!
  lon: Float!
}
`