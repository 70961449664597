/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
 */
export interface FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner {
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'type'?: FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'targetUri'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'imageUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'categoryId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'customText'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'bgColor'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'customTextDisplay'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'shortcutId'?: string;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner
     */
    'decoration'?: string;
}

export const FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum = {
    UNSPECIFIED: 'KEYWORD_TYPE_UNSPECIFIED',
    KEYWORD: 'KEYWORD_TYPE_KEYWORD',
    SHORTCUT: 'KEYWORD_TYPE_SHORTCUT'
} as const;

export type FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum = typeof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum[keyof typeof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInnerTypeEnum];


