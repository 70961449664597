/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ApiV1CommunitySearchGet200Response } from '../models';
// @ts-ignore
import { ApiV1CommunitySearchGet200Response1 } from '../models';
// @ts-ignore
import { ApiV1CommunitySearchGetRequest } from '../models';
/**
 * CommunityApi - axios parameter creator
 * @export
 */
export const CommunityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 커뮤니티-동네생활, 모임 검색
         * @summary 커뮤니티-동네생활, 모임 검색
         * @param {string} query 
         * @param {string} regionId 
         * @param {number} pageSize 
         * @param {string} [pageToken] 
         * @param {'SEARCH_SORT_UNSPECIFIED' | 'SEARCH_SORT_RELEVANT' | 'SEARCH_SORT_RECENT'} [sort] 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1CommunitySearchGet: async (query: string, regionId: string, pageSize: number, pageToken?: string, sort?: 'SEARCH_SORT_UNSPECIFIED' | 'SEARCH_SORT_RELEVANT' | 'SEARCH_SORT_RECENT', xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'query' is not null or undefined
            assertParamExists('apiV1CommunitySearchGet', 'query', query)
            // verify required parameter 'regionId' is not null or undefined
            assertParamExists('apiV1CommunitySearchGet', 'regionId', regionId)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('apiV1CommunitySearchGet', 'pageSize', pageSize)
            const localVarPath = `/api/v1/community/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (regionId !== undefined) {
                localVarQueryParameter['regionId'] = regionId;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (pageToken !== undefined) {
                localVarQueryParameter['pageToken'] = pageToken;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 커뮤니티-동네생활, 모임 검색
         * @summary 커뮤니티-동네생활, 모임 검색
         * @param {ApiV1CommunitySearchGetRequest} apiV1CommunitySearchGetRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommunitySearchPost: async (apiV1CommunitySearchGetRequest: ApiV1CommunitySearchGetRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'apiV1CommunitySearchGetRequest' is not null or undefined
            assertParamExists('apiV1CommunitySearchPost', 'apiV1CommunitySearchGetRequest', apiV1CommunitySearchGetRequest)
            const localVarPath = `/api/v1/community/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xAuthToken !== undefined && xAuthToken !== null) {
                localVarHeaderParameter['x-auth-token'] = String(xAuthToken);
            }

            if (acceptLanguage !== undefined && acceptLanguage !== null) {
                localVarHeaderParameter['accept-language'] = String(acceptLanguage);
            }

            if (xUserAgent !== undefined && xUserAgent !== null) {
                localVarHeaderParameter['x-user-agent'] = String(xUserAgent);
            }

            if (xCountryCode !== undefined && xCountryCode !== null) {
                localVarHeaderParameter['x-country-code'] = String(xCountryCode);
            }

            if (xAdId !== undefined && xAdId !== null) {
                localVarHeaderParameter['x-ad-id'] = String(xAdId);
            }

            if (xDeviceId !== undefined && xDeviceId !== null) {
                localVarHeaderParameter['x-device-id'] = String(xDeviceId);
            }

            if (xRequestId !== undefined && xRequestId !== null) {
                localVarHeaderParameter['x-request-id'] = String(xRequestId);
            }

            if (authorization !== undefined && authorization !== null) {
                localVarHeaderParameter['authorization'] = String(authorization);
            }

            if (contentType !== undefined && contentType !== null) {
                localVarHeaderParameter['content-type'] = String(contentType);
            }

            if (xKarrotUserId !== undefined && xKarrotUserId !== null) {
                localVarHeaderParameter['x-karrot-user-id'] = String(xKarrotUserId);
            }

            if (xKarrotRegionId !== undefined && xKarrotRegionId !== null) {
                localVarHeaderParameter['x-karrot-region-id'] = String(xKarrotRegionId);
            }

            if (xKarrotSessionId !== undefined && xKarrotSessionId !== null) {
                localVarHeaderParameter['x-karrot-session-id'] = String(xKarrotSessionId);
            }

            if (xAuthKarrotUserId !== undefined && xAuthKarrotUserId !== null) {
                localVarHeaderParameter['x-auth-karrot-user-id'] = String(xAuthKarrotUserId);
            }

            if (xAuthType !== undefined && xAuthType !== null) {
                localVarHeaderParameter['x-auth-type'] = String(xAuthType);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(apiV1CommunitySearchGetRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommunityApi - functional programming interface
 * @export
 */
export const CommunityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommunityApiAxiosParamCreator(configuration)
    return {
        /**
         * 커뮤니티-동네생활, 모임 검색
         * @summary 커뮤니티-동네생활, 모임 검색
         * @param {string} query 
         * @param {string} regionId 
         * @param {number} pageSize 
         * @param {string} [pageToken] 
         * @param {'SEARCH_SORT_UNSPECIFIED' | 'SEARCH_SORT_RELEVANT' | 'SEARCH_SORT_RECENT'} [sort] 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async apiV1CommunitySearchGet(query: string, regionId: string, pageSize: number, pageToken?: string, sort?: 'SEARCH_SORT_UNSPECIFIED' | 'SEARCH_SORT_RELEVANT' | 'SEARCH_SORT_RECENT', xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1CommunitySearchGet200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CommunitySearchGet(query, regionId, pageSize, pageToken, sort, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 커뮤니티-동네생활, 모임 검색
         * @summary 커뮤니티-동네생활, 모임 검색
         * @param {ApiV1CommunitySearchGetRequest} apiV1CommunitySearchGetRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiV1CommunitySearchPost(apiV1CommunitySearchGetRequest: ApiV1CommunitySearchGetRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiV1CommunitySearchGet200Response1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiV1CommunitySearchPost(apiV1CommunitySearchGetRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommunityApi - factory interface
 * @export
 */
export const CommunityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommunityApiFp(configuration)
    return {
        /**
         * 커뮤니티-동네생활, 모임 검색
         * @summary 커뮤니티-동네생활, 모임 검색
         * @param {string} query 
         * @param {string} regionId 
         * @param {number} pageSize 
         * @param {string} [pageToken] 
         * @param {'SEARCH_SORT_UNSPECIFIED' | 'SEARCH_SORT_RELEVANT' | 'SEARCH_SORT_RECENT'} [sort] 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        apiV1CommunitySearchGet(query: string, regionId: string, pageSize: number, pageToken?: string, sort?: 'SEARCH_SORT_UNSPECIFIED' | 'SEARCH_SORT_RELEVANT' | 'SEARCH_SORT_RECENT', xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options?: any): AxiosPromise<ApiV1CommunitySearchGet200Response> {
            return localVarFp.apiV1CommunitySearchGet(query, regionId, pageSize, pageToken, sort, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, options).then((request) => request(axios, basePath));
        },
        /**
         * 커뮤니티-동네생활, 모임 검색
         * @summary 커뮤니티-동네생활, 모임 검색
         * @param {ApiV1CommunitySearchGetRequest} apiV1CommunitySearchGetRequest 
         * @param {string} [xAuthToken] x-auth-token
         * @param {string} [acceptLanguage] accept-language
         * @param {string} [xUserAgent] x-user-agent
         * @param {string} [xCountryCode] x-country-code
         * @param {string} [xAdId] x-ad-id
         * @param {string} [xDeviceId] x-device-id
         * @param {string} [xRequestId] x-request-id
         * @param {string} [authorization] authorization
         * @param {string} [contentType] content-type
         * @param {string} [xKarrotUserId] x-karrot-user-id
         * @param {string} [xKarrotRegionId] x-karrot-region-id
         * @param {string} [xKarrotSessionId] x-karrot-session-id
         * @param {string} [xAuthKarrotUserId] x-auth-karrot-user-id
         * @param {string} [xAuthType] x-auth-type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiV1CommunitySearchPost(apiV1CommunitySearchGetRequest: ApiV1CommunitySearchGetRequest, xAuthToken?: string, acceptLanguage?: string, xUserAgent?: string, xCountryCode?: string, xAdId?: string, xDeviceId?: string, xRequestId?: string, authorization?: string, contentType?: string, xKarrotUserId?: string, xKarrotRegionId?: string, xKarrotSessionId?: string, xAuthKarrotUserId?: string, xAuthType?: string, options?: any): AxiosPromise<ApiV1CommunitySearchGet200Response1> {
            return localVarFp.apiV1CommunitySearchPost(apiV1CommunitySearchGetRequest, xAuthToken, acceptLanguage, xUserAgent, xCountryCode, xAdId, xDeviceId, xRequestId, authorization, contentType, xKarrotUserId, xKarrotRegionId, xKarrotSessionId, xAuthKarrotUserId, xAuthType, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiV1CommunitySearchGet operation in CommunityApi.
 * @export
 * @interface CommunityApiApiV1CommunitySearchGetRequest
 */
export interface CommunityApiApiV1CommunitySearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly query: string

    /**
     * 
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly regionId: string

    /**
     * 
     * @type {number}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly pageSize: number

    /**
     * 
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly pageToken?: string

    /**
     * 
     * @type {'SEARCH_SORT_UNSPECIFIED' | 'SEARCH_SORT_RELEVANT' | 'SEARCH_SORT_RECENT'}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly sort?: 'SEARCH_SORT_UNSPECIFIED' | 'SEARCH_SORT_RELEVANT' | 'SEARCH_SORT_RECENT'

    /**
     * x-auth-token
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchGet
     */
    readonly xAuthType?: string
}

/**
 * Request parameters for apiV1CommunitySearchPost operation in CommunityApi.
 * @export
 * @interface CommunityApiApiV1CommunitySearchPostRequest
 */
export interface CommunityApiApiV1CommunitySearchPostRequest {
    /**
     * 
     * @type {ApiV1CommunitySearchGetRequest}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly apiV1CommunitySearchGetRequest: ApiV1CommunitySearchGetRequest

    /**
     * x-auth-token
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xAuthToken?: string

    /**
     * accept-language
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly acceptLanguage?: string

    /**
     * x-user-agent
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xUserAgent?: string

    /**
     * x-country-code
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xCountryCode?: string

    /**
     * x-ad-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xAdId?: string

    /**
     * x-device-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xDeviceId?: string

    /**
     * x-request-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xRequestId?: string

    /**
     * authorization
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly authorization?: string

    /**
     * content-type
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly contentType?: string

    /**
     * x-karrot-user-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xKarrotUserId?: string

    /**
     * x-karrot-region-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xKarrotRegionId?: string

    /**
     * x-karrot-session-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xKarrotSessionId?: string

    /**
     * x-auth-karrot-user-id
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xAuthKarrotUserId?: string

    /**
     * x-auth-type
     * @type {string}
     * @memberof CommunityApiApiV1CommunitySearchPost
     */
    readonly xAuthType?: string
}

/**
 * CommunityApi - object-oriented interface
 * @export
 * @class CommunityApi
 * @extends {BaseAPI}
 */
export class CommunityApi extends BaseAPI {
    /**
     * 커뮤니티-동네생활, 모임 검색
     * @summary 커뮤니티-동네생활, 모임 검색
     * @param {CommunityApiApiV1CommunitySearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof CommunityApi
     */
    public apiV1CommunitySearchGet(requestParameters: CommunityApiApiV1CommunitySearchGetRequest, options?: AxiosRequestConfig) {
        return CommunityApiFp(this.configuration).apiV1CommunitySearchGet(requestParameters.query, requestParameters.regionId, requestParameters.pageSize, requestParameters.pageToken, requestParameters.sort, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 커뮤니티-동네생활, 모임 검색
     * @summary 커뮤니티-동네생활, 모임 검색
     * @param {CommunityApiApiV1CommunitySearchPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommunityApi
     */
    public apiV1CommunitySearchPost(requestParameters: CommunityApiApiV1CommunitySearchPostRequest, options?: AxiosRequestConfig) {
        return CommunityApiFp(this.configuration).apiV1CommunitySearchPost(requestParameters.apiV1CommunitySearchGetRequest, requestParameters.xAuthToken, requestParameters.acceptLanguage, requestParameters.xUserAgent, requestParameters.xCountryCode, requestParameters.xAdId, requestParameters.xDeviceId, requestParameters.xRequestId, requestParameters.authorization, requestParameters.contentType, requestParameters.xKarrotUserId, requestParameters.xKarrotRegionId, requestParameters.xKarrotSessionId, requestParameters.xAuthKarrotUserId, requestParameters.xAuthType, options).then((request) => request(this.axios, this.basePath));
    }
}
