/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner } from './fleamarket-search-success-response-documents-inner-component-related-keyword-keywords-inner';

/**
 * 
 * @export
 * @interface FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeyword
 */
export interface FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeyword {
    /**
     * 
     * @type {Array<FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner>}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeyword
     */
    'keywords'?: Array<FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordKeywordsInner>;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeyword
     */
    'template': FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordTemplateEnum;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeyword
     */
    'slot': string;
}

export const FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordTemplateEnum = {
    UNSPECIFIED: 'RELATED_KEYWORDS_TEMPLATE_UNSPECIFIED',
    TOP: 'RELATED_KEYWORDS_TEMPLATE_TOP',
    FILTER: 'RELATED_KEYWORDS_TEMPLATE_FILTER',
    LIST_VIEW: 'RELATED_KEYWORDS_TEMPLATE_LIST_VIEW'
} as const;

export type FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordTemplateEnum = typeof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordTemplateEnum[keyof typeof FleamarketSearchSuccessResponseDocumentsInnerComponentRelatedKeywordTemplateEnum];


