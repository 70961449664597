/* tslint:disable */
/* eslint-disable */
/**
 * Test swagger
 * Testing the Fastify swagger API
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { FleamarketSearchErrorResponseDetailsTitle } from './fleamarket-search-error-response-details-title';

/**
 * 
 * @export
 * @interface FleamarketSearchErrorResponseDetails
 */
export interface FleamarketSearchErrorResponseDetails {
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchErrorResponseDetails
     */
    'type': FleamarketSearchErrorResponseDetailsTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchErrorResponseDetails
     */
    'displayType': FleamarketSearchErrorResponseDetailsDisplayTypeEnum;
    /**
     * 
     * @type {FleamarketSearchErrorResponseDetailsTitle}
     * @memberof FleamarketSearchErrorResponseDetails
     */
    'title'?: FleamarketSearchErrorResponseDetailsTitle;
    /**
     * 
     * @type {FleamarketSearchErrorResponseDetailsTitle}
     * @memberof FleamarketSearchErrorResponseDetails
     */
    'message'?: FleamarketSearchErrorResponseDetailsTitle;
    /**
     * 
     * @type {string}
     * @memberof FleamarketSearchErrorResponseDetails
     */
    'metadataProtoJson'?: string;
}

export const FleamarketSearchErrorResponseDetailsTypeEnum = {
    UNSPECIFIED: 'ERROR_TYPE_UNSPECIFIED',
    LONG_TAIL_QUERY: 'ERROR_TYPE_LONG_TAIL_QUERY',
    NULL_QUERY: 'ERROR_TYPE_NULL_QUERY',
    UNAUTHENTICATED: 'ERROR_TYPE_UNAUTHENTICATED',
    ABUSER_DETECTED: 'ERROR_TYPE_ABUSER_DETECTED'
} as const;

export type FleamarketSearchErrorResponseDetailsTypeEnum = typeof FleamarketSearchErrorResponseDetailsTypeEnum[keyof typeof FleamarketSearchErrorResponseDetailsTypeEnum];
export const FleamarketSearchErrorResponseDetailsDisplayTypeEnum = {
    UNSPECIFIED: 'DISPLAY_TYPE_UNSPECIFIED',
    SNACKBAR: 'DISPLAY_TYPE_SNACKBAR',
    DIALOG: 'DISPLAY_TYPE_DIALOG',
    NONE: 'DISPLAY_TYPE_NONE'
} as const;

export type FleamarketSearchErrorResponseDetailsDisplayTypeEnum = typeof FleamarketSearchErrorResponseDetailsDisplayTypeEnum[keyof typeof FleamarketSearchErrorResponseDetailsDisplayTypeEnum];


